import React from "react";
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { IoIosArrowDropdown } from "react-icons/io";
import { Link } from "react-scroll";
import Typical from "react-typical";
import Shake from "react-reveal/Shake";
import { Bounce, Slide } from "react-reveal";

const Home = () => {
  const handleClickGitHub = () => {
    window.open("https://github.com/anuaroslan");
  };
  const handleClickLinkedIn = () => {
    window.open("https://linkedin.com/in/anuar-roslan-171645140");
  };
  const handleClickInstagram = () => {
    window.open("https://instagram.com/anuaroslan");
  };

  return (
    <div className="box flex flex-col md:container md:mx-auto">
      <div
        id="home"
        className="flex flex-col justify-center align-center  font-sans text-gray-700 text-center bg-transparent min-h-screen lg:p-52 sm:p-20"
      >
        <Shake duration={3000} delay={500}>
          <h2 className="text-3xl md:text-5xl py-2">Greetings, I'm Anuar!</h2>
        </Shake>
        <h3 className="text-2xl md:text-4xl py-2 flex flex-row justify-center">
          I am a{"  "}
          <Typical
            className="pl-2"
            steps={[
              " Software Engineer.",
              3000,
              "Sound Engineer.",
              3000,
              "Music Producer.",
              3000,
            ]}
            loop={Infinity}
            wrapper="p"
          />
        </h3>
        <Bounce up cascade duration={2000}>
          <div className="flex justify-center text-3xl md:text-5xl py-2">
            <button
              onClick={handleClickGitHub}
              className="transition duration-200 ease-in-out hover:text-gray-600 transform hover:scale-105"
            >
              <FaGithub className="mx-4" />
            </button>
            <button
              onClick={handleClickLinkedIn}
              className="transition duration-200 ease-in-out hover:text-gray-600 transform hover:scale-105"
            >
              <FaLinkedin className="mx-4" />
            </button>
            <button className="transition duration-200 ease-in-out hover:text-gray-600 transform hover:scale-105">
              <Link to="contact" spy={true} smooth={true} offset={-0}>
                <FiMail className="mx-4" />
              </Link>
            </button>
            <button
              onClick={handleClickInstagram}
              className="transition duration-200 ease-in-out hover:text-gray-600 transform hover:scale-105"
            >
              <FaInstagram className="mx-4" />
            </button>
          </div>
        </Bounce>
      </div>
      <div className="animate-bounce flex justify-center relative bottom-20 md:bottom-12 text-2xl md:text-4xl">
        <Slide bottom duration={2000}>
          <Link to="about" spy={true} smooth={true} offset={-0}>
            <button>
              <IoIosArrowDropdown />
            </button>
          </Link>
        </Slide>
      </div>
    </div>
  );
};

export default Home;
