import React, { useState, useEffect } from "react";
import ProfilePic from "../images/newProfile.png";
import HideOnScroll from "./HideOnScroll";
import { Link } from "react-scroll";
import { Fade, Flip } from "react-reveal";

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []);
  return (
    <HideOnScroll>
      <nav
        className={
          scroll
            ? "z-10 down fixed flex bg-white p-4 bg-opacity-70 shadow-xs justify-center md:justify-between font-mono items-center min-w-full"
            : "top fixed flex bg-white p-4 justify-center align-center md:justify-between font-mono items-center w-full md:min-w-full"
        }
      >
        <div className=" invisible md:visible ">
          <Flip top duration={2000}>
            <Link to="home" spy={true} smooth={true} offset={-0}>
              <button>
                <img
                  src={ProfilePic}
                  alt="Anuar Roslan"
                  className="w-0 md:w-12 rounded-full"
                />
              </button>
            </Link>
          </Flip>
        </div>
        <div className="flex justify-center item-center text-sm md:text-base">
          <Fade cascade top duration={2000}>
            <ul className="flex text-gray-700 ">
              <Link to="about" spy={true} smooth={true} offset={-0}>
                <li className="md:mr-14 transition duration-200 ease-in-out hover:text-gray-500 transform hover:scale-105">
                  <button>About</button>
                </li>
              </Link>
              <Link to="projects" spy={true} smooth={true} offset={-0}>
                <li className="mx-10 sm:mx-0 md:mr-14 transition duration-200 ease-in-out hover:text-gray-500 transform hover:scale-105">
                  <button>Projects</button>
                </li>
              </Link>
              <Link to="contact" spy={true} smooth={true} offset={-0}>
                <li className="md:mr-14 transition duration-200 ease-in-out hover:text-gray-500 transform hover:scale-105">
                  <button>Contact</button>
                </li>
              </Link>
            </ul>
          </Fade>
        </div>
      </nav>
    </HideOnScroll>
  );
};

export default Navbar;
