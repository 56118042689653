import React from "react";
import Fade from "react-reveal/Fade";
import { Bounce, Rotate, Flip } from "react-reveal";

function Contact() {
  return (
    <div
      id="contact"
      className="flex flex-col justify-center font-sans bg-gray-100 p-0 min-h-screen text-gray-700"
    >
      <div className="md:container md:mx-auto">
        <div className="Project title pt-20 px-10">
          <Fade left>
            <h2 className="text-xl md:text-3xl">Get In Touch</h2>
            <div className="bg-gray-400 rounded-full w-44 h-1 mb-10"></div>
          </Fade>
        </div>
        {/* form */}
        <form
          className="text-xs max-w-7 mx-10 my-10 md:mx-1/2 lg:mx-60"
          action="https://getform.io/f/00df50db-4b2e-4e3e-ac1f-f86185c1cb57"
          method="POST"
        >
          <div className="flex flex-wrap -mx-3 mb-6">
            {/* First Name */}
            <Rotate top left>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  First Name
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Firdaus"
                  name="first name"
                />
              </div>
            </Rotate>
            {/* Second Name */}
            <Rotate top right>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Yazren"
                  name="last name"
                />
              </div>
            </Rotate>
          </div>
          {/* E-Mail */}
          <Bounce bottom>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  E-mail
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  type="email"
                  name="email"
                />
              </div>
            </div>
          </Bounce>
          {/* Message */}
          <Flip bottom>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Message
                </label>
                <textarea
                  className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                  id="message"
                  name="message"
                ></textarea>
              </div>
            </div>
          </Flip>
          {/* Submit */}
          <div className="md:flex md:items-center pb-10 ">
            <div className="md:w-1/3">
              <Flip>
                <button
                  className="shadow bg-gray-400 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="submit"
                >
                  Send
                </button>
              </Flip>
            </div>
            <div className="md:w-2/3"></div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
