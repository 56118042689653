import React from "react";
import ProfilePic from "../images/newProfile.png";
import { Fade, Zoom } from "react-reveal";

const About = () => {
  return (
    <div className="bg-gray-100">
      <div
        id="about"
        className="flex flex-col justify-center md:flex-row text-gray-700 font-sans p-0 min-h-screen md:container md:mx-auto"
      >
        <div className="flex flex-col justify-center pt-10 md:py-0 px-10 md:w-1/2 text-xs md:text-base">
          <Fade duration={1000} left>
            <h2 className="text-xl md:text-3xl cursor-default">About</h2>
          </Fade>
          <Fade duration={1000} left>
            <div className="bg-gray-400 w-24 h-1 rounded-full mb-5"></div>
          </Fade>

          <div className="w-20 "></div>
          <Fade bottom cascade duration={1500}>
            <span>
              <p className="pt-4 text-base md:text-base xl:text-xl w-full inline-block break-normal">
                Hello, hope you are feeling marvelous. My name is Anuar and I am
                a passionate and highly skilled Frontend Engineer with a deep
                love for crafting exceptional user experiences. I have expertise
                in a variety of frontend technologies, including HTML, CSS, and
                JavaScript, TypeScript, turning ideas into beautifully
                responsive and user-friendly web applications. I do freelance
                work under Tukang Dev and Maiya Studio as a Developer. My main
                language is currently Javascript and Typescript, and I'm mostly working with
                React JS. However, I am constanstly exploring with a lot of
                things.
              </p>
            </span>
            {/* <span>
              <p className="pt-4 text-base md:text-base xl:text-xl w-full inline-block break-normal">
                When not writing elegant code, you can find me enjoying music.
                Graduated with a Diploma in Sound Engineering at London Academy of
                Music Production, I am now Engineering Softwares. I also enjoy
                DJ-ing on my free time.
              </p>
            </span> */}
          </Fade>
        </div>
        <div className="flex justify-center items-center mt-10 md:mt-0 md:w-1/2 ">
          <Zoom right duration={1500}>
            <img
              src={ProfilePic}
              alt="Anuar Roslan"
              className="object-cover w-48 mb-5 mt-5 md:w-80 md:h-80 rounded-full shadow-2xl"
            />
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default About;
