import React from "react";
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { MdReplay } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import { Link } from "react-scroll";
import { Fade, Flip } from "react-reveal";

const Footer = () => {
  const handleClickGitHub = () => {
    window.open("https://github.com/anuaroslan");
  };
  const handleClickLinkedIn = () => {
    window.open("https://linkedin.com/in/anuar-roslan-171645140");
  };
  const handleClickInstagram = () => {
    window.open("https://instagram.com/anuaroslan");
  };

  return (
    <div className=" bg-white shadow-2xl">
      <div className="flex justify-between items-center font-sans md:text-lg  py-5 text-gray-700 px-4 sm:px-10 md:container md:mx-auto">
        <Fade left duration={2000}>
          <h2 className="text-xs md:text-base">
            © 2021 - Designed & Developed by Anuar Roslan.
          </h2>
        </Fade>
        <div className="invisible lg:visible w-0 animate-pulse text-4xl relative -left-28 transform">
          <Fade bottom duration={2000}>
            <Link to="home" spy={true} smooth={true} offset={-0}>
              <button>
                <MdReplay />
              </button>
            </Link>
          </Fade>
        </div>
        <Flip cascade>
          <div className="socials flex justify-center text-xl sm:text-3xl">
            <button
              onClick={handleClickGitHub}
              className="transition duration-200 ease-in-out hover:text-gray-600 transform hover:scale-105"
            >
              <FaGithub className="mx-4" />
            </button>
            <button
              onClick={handleClickLinkedIn}
              className="transition duration-200 ease-in-out hover:text-gray-600 transform hover:scale-105"
            >
              <FaLinkedin className="mx-4" />
            </button>
            <button className="transition duration-200 ease-in-out hover:text-gray-600 transform hover:scale-105">
              <Link to="contact" spy={true} smooth={true} offset={-0}>
                <FiMail className="mx-4" />
              </Link>
            </button>
            <button
              onClick={handleClickInstagram}
              className="transition duration-200 ease-in-out hover:text-gray-600 transform hover:scale-105"
            >
              <FaInstagram className="ml-4" />
            </button>
          </div>
        </Flip>
      </div>
    </div>
  );
};

export default Footer;
