import React from "react";
import Moshify from "../images/screenMoshify.png";
import Aminroslan from "../images/screenAminroslan.png";
import Anuaroslan from "../images/screenAnuaroslanNew.png";
import { Bounce, Fade } from "react-reveal";

const Project = () => {
  const handleClickMoshify = () => {
    window.open("https://anuarfy.netlify.app/");
  };
  const handleClickAnuaroslan = () => {
    window.open("https://anuaroslan.com/");
  };
  const handleClickAminroslan = () => {
    window.open("https://aminroslanbyanuaroslan.netlify.app/");
  };
  const handleClickRealAminroslan = () => {
    window.open("https://aminroslan.com/");
  };

  return (
    <div className="">
      <div
        id="projects"
        className="flex flex-col justify-center font-sans bg-transparent p-0 min-h-screen text-gray-700 md:container md:mx-auto"
      >
        {/* Project */}
        <Bounce bottom cascade duration={1800}>
          <div className="Project title pt-28 px-10">
            <Fade left duration={1000}>
              <h2 className="text-xl md:text-3xl">Projects</h2>
            </Fade>
            <Fade left duration={1000}>
              <div className="bg-gray-400 rounded-full w-28 h-1 mb-1"></div>
            </Fade>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-end justify-evenly items-center px-6 text-sm">
            {/* First */}
            <div className="border border-opacity-0 shadow-lg hover:shadow-2xl rounded-lg w-full h-full px-5 py-5 m-10 max-w-md flex flex-col justify-center items-center hover:-translate-y-1.5 transform transition duration-200 ease-in-out">
              <img
                src={Moshify}
                alt="Moshify"
                onClick={handleClickMoshify}
                className="min-w-full mt-2 rounded-xl shadow-xl cursor-pointer"
              />
              <h2 className="text-xs uppercase text-white p-1 bg-gray-500 min-w-min min-h-min relative bottom-3 -right-14 sm:-right-28 rounded-sm">
                HTML CSS
              </h2>
              <div className="h-64">
                <h2
                  onClick={handleClickMoshify}
                  className="text-lg md:text-2xl max-w-max hover:text-gray-500 hover:-translate-y-0.5 transform transition duration-200 ease-in-out cursor-pointer"
                >
                  Moshify
                </h2>
                <div className="bg-gray-400 w-24 rounded-full h-1 mb-5"></div>
                <p className="lg:text-xs xl:text-sm">
                  Moshify is the first website I've ever done right after I
                  finished my HTML & CSS Course by Mosh Hamedani. Nothing
                  impressive here, but it's in my portfolio as an achievement
                  for my first site. Credits to the man for one of the best
                  course out there!
                </p>
              </div>
            </div>
            {/* second */}
            <div className="border border-opacity-0 shadow-lg hover:shadow-2xl rounded-lg w-full h-full px-5 py-5 m-10 max-w-md flex flex-col justify-center items-center hover:-translate-y-1.5 transform transition duration-200 ease-in-out">
              <img
                src={Anuaroslan}
                alt="Moshify"
                onClick={handleClickAnuaroslan}
                className="min-w-full mt-2 rounded-xl shadow-xl cursor-pointer"
              />
              <h2 className="text-xs uppercase text-white p-1 bg-gray-500 min-w-min min-h-min relative bottom-3 -right-14 sm:-right-28 rounded-sm">
                React JS
              </h2>
              <div className="h-64">
                <h2
                  onClick={handleClickAnuaroslan}
                  className="text-lg md:text-2xl max-w-max hover:text-gray-500 hover:-translate-y-0.5 transform transition duration-200 ease-in-out cursor-pointer"
                >
                  anuaroslan.com
                </h2>
                <div className="bg-gray-400 w-44 rounded-full h-1 mb-5"></div>
                <p className="lg:text-xs xl:text-sm">
                  Well say hello to yourself, this is the site you're looking
                  at! It's my first official site done with React JS and my
                  first time styling with Tailwind CSS for the best efficiency
                  in workflow. Decided to keep it simple for it, inspired by my
                  brother's web portfolio aminroslan.com as he introduced me to
                  Software Engineering. Cheers to my brother!
                </p>
              </div>
            </div>
            {/* third */}
            <div className="border border-opacity-0 shadow-lg hover:shadow-2xl rounded-lg w-full h-full px-5 py-5 m-10 max-w-md flex flex-col justify-center items-center hover:-translate-y-1.5 transform transition duration-200 ease-in-out">
              <img
                src={Aminroslan}
                alt="Moshify"
                onClick={handleClickAminroslan}
                className="min-w-full mt-2 rounded-xl shadow-xl cursor-pointer"
              />
              <h2 className="text-xs uppercase text-white p-1 bg-gray-500 min-w-min min-h-min relative bottom-3 -right-14 sm:-right-28 rounded-sm">
                React JS
              </h2>
              <div className="h-64">
                <h2
                  onClick={handleClickAminroslan}
                  className="text-lg md:text-2xl max-w-max hover:text-gray-500 hover:-translate-y-0.5 transform transition duration-200 ease-in-out cursor-pointer"
                >
                  My Brother's Portfolio
                </h2>
                <div className="bg-gray-400 w-56 h-1 rounded-full mb-5"></div>
                <p className="lg:text-xs xl:text-sm">
                  Quite similar to my own site that you're looking at, but he
                  actually challenged me to replicate this site to see my
                  potential and got him amazed. Initially, I was building the
                  site with Vanilla Javascript and then converted everything
                  into React JS and using it for the first time. You can
                  checkout the original one at{" "}
                  <strong
                    onClick={handleClickRealAminroslan}
                    className="font-semibold hover:text-blue-400 cursor-pointer "
                  >
                    www.aminroslan.com
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </Bounce>
      </div>
    </div>
  );
};

export default Project;
